.Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  width: 100% vw;
  margin: 20px 0px 0px 0px;
  background-color: #97c0ab;
  box-shadow: 0 0 10px #798f84;
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  padding-right: 15px;
}

.Footer i {
  color: rgb(48, 39, 39);
  background-color: none;
  font-size: 20px;
}

@media only screen and (min-width: 800px) {
  .Footer .fa-brands {
    font-size: 30px;
  }

  .Footer button {
    font-size: 20px;
    width: 100px;
    margin-right: 30px;
    margin-bottom: 5px;
  }
}
