#home {
  font-weight: lighter;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#example {
  width: 100%;
  height: 200px;
}
#weAre {
  font-size: 20px;
}
.mostpopular {
  font-family: Comic Neue, cursive;
}

#populartitle {
  text-align: center;
  margin-bottom: 80px;
  margin-top: 100px;
}

.popularitems {
  display: grid;
  grid: 80%;
  justify-content: center;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  margin-top: 10px;
  font-weight: bolder;
}
.item img {
  border-radius: 4%;
}
.item > * {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.description > * {
  margin: 0;
}
#popcorn {
  margin: 50px 0 10px 0;
}

@media only screen and (min-width: 900px) {
  #home {
    justify-content: space-between;
  }
  .item > * {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #weAre {
    font-size: 15rem;
  }

  .popularitems {
    grid: 50% / auto auto auto;
  }
  .description {
    font-size: 1.4rem;
  }
}
