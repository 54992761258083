.info-card {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-evenly;
  margin: 2rem;
  font-size: 1.2rem;
  box-shadow: 1px 10px 10px rgba(0, 0, 0, 0.45);
  background-color: whitesmoke;
  border-radius: 5px;
}
.info-card .title {
  font-family: "feelingloney";
  font-weight: lighter;
  margin-bottom: 0;
}
.info-card .image-section .highlighted-img {
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #eeea87;
  box-shadow: 0 0 10px #f6f4b9;
  max-width: 100%;
}

.info-card .text-section {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
.info-card .text-section .title {
  text-align: center;
}

.info-card .text-section .content {
  text-align: center;

  font-family: "frenchpress";
  line-height: 1.2;
}

@media only screen and (min-width: 900px) {
  .info-card {
    flex-direction: row;
    background-color: transparent;
    margin-bottom: 30px;
    border-radius: 0;
    margin: 2rem;

    box-shadow: none;
    height: 100%;
  }

  .info-card .text-section {
    flex: 1;
  }

  .info-card .image-section {
    flex: 1;
    margin-left: 50px;
  }

  .info-card .text-section {
    font-size: 3rem;
    margin: auto;
    line-height: 1.2;
    background-color: rgba(221, 220, 214, 0.3);
    box-shadow: 0 0 15px 10px #dddcd6;
  }
}
