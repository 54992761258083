#howItWorks {
  text-align: center;
  text-shadow: 0.5px 0.5px #e4f1f3;
  color: #304e52;
}

#howItWorksDiv {
  color: white;
  font-family: "KGBeneathYourBeautifulChunk";
}

#headerHIW {
  color: #304e52;
  text-shadow: 1px 1px #f9feff;
  text-align: center;
  margin-bottom: 10px;
}
.para {
  color: #304e52;
  text-align: center;
  margin-top: 10px;
  margin-right: 5px;
  font-family: "frenchpress";
  font-size: 1.5rem;
}
#howItWorksComponents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
}

.weight {
  width: 120px;
  height: 110px;
  margin: 10px;
}
.fillJar {
  width: 80px;
  height: 110px;
  margin: 10px;
}
.fullJar {
  width: 70px;
  height: 110px;
  margin: 15px 10px;
}

#weighDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid #304e52;
  width: 85%;
  border-radius: 5px;
  background-color: #c1dee1;
}
#weighDivInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#fillDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 85%;
  border: 2px solid #304e52;
  border-radius: 5px;
  background-color: #c1dee1;
}
#refillInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
}
#fullDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 85%;
  border: 2px solid #304e52;
  border-radius: 5px;
  background-color: #c1dee1;
}
#checkout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin-right: 10px;
}

@media only screen and (min-width: 700px) {
  #howItWorksComponents {
    align-items: center;
    flex-direction: row;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 50px;
  }
  #howItWorksDiv {
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  #weighDiv:hover {
    box-shadow: 0 0 10px #f4f3aa, 0 0 15px #f4f3aa;
    color: #f4f3aa;
    border: 2px solid #e9e78b;
  }
  #fillDiv:hover {
    box-shadow: 0 0 10px #f4f3aa, 0 0 15px #f4f3aa;
    color: #f4f3aa;
    border: 2px solid #e9e78b;
  }
  #fullDiv:hover {
    box-shadow: 0 0 10px #f4f3aa, 0 0 15px #f4f3aa;
    color: #f4f3aa;
    border: 2px solid #e9e78b;
  }
  #howItWorks {
    position: relative;
    font-size: 4rem;

    margin-bottom: 100px;
    text-align: center;
  }

  .para {
    font-size: 2rem;
    line-height: 2rem;
  }
  /* #howItWorks:before {
    content: ' ';
    height: 2px;
    width: 90px;
    background: #304e52;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
  }

  #howItWorks:after {
    content: ' ';
    height: 2px;
    width: 90px;
    background: #304e52;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
  } */

  #weighDiv {
    /* margin-left: 20px;
    margin-right: 20px; */
    margin: 0;
    height: 200px;
    flex-basis: 100%;
    justify-content: center;
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    padding: 1rem;
    line-height: 1.5rem;
    background: #92afb3(67, 95%, 95%, 1);
    border: solid 5px #204247;
  }
  #fillDiv {
    /* margin-right: 20px;
    margin-bottom: 40px; */
    margin: 0;
    height: 200px;
    flex-basis: 100%;
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    padding: 1em;
    line-height: 1.5em;
    background: #92afb3(67, 95%, 95%, 1);
    border: solid 5px #204247;
  }
  #fullDiv {
    /* margin-right: 20px;
    margin-bottom: 40px; */
    margin: 0;
    height: 200px;
    flex-basis: 100%;
    justify-content: center;
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    padding: 1em;
    line-height: 1.5em;
    background: #92afb3(67, 95%, 95%, 1);
    border: solid 5px #204247;
  }
  #refillInfo {
    width: 100%;
  }
  #weighP {
    width: 90%;
  }
  #howitworkstotalpage {
    height: 100vh;
  }
}
