@font-face {
  font-family: 'feelingloney';
  src: url('../public/feelingloney.ttf') format('truetype');
}
@font-face {
  font-family: 'aaronfresh';
  src: url('../public/AaronFresh 2.otf') format('truetype');
}
@font-face {
  font-family: 'KGBeneathYourBeautifulChunk';
  src: url('../public/KGBeneathYourBeautifulChunk.ttf') format('truetype');
}
@font-face {
  font-family: 'Citylight';
  src: url('../public/Citylight.otf') format('truetype');
}
@font-face {
  font-family: 'frenchpress';
  src: url('../public/frenchpress.otf') format('truetype');
}


html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: 'frenchpress';
  background-color: #fff;
  /* background-image: url('../public/backgroundmobile.png');
  background-repeat: no-repeat;
  background-size: cover; */
}
