.productTitle {
  font-size: 5rem;
  margin-bottom: 0;
  color: #121307;
  text-shadow: 1px 1px #637e14;
}
.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.productList {
  background-color: rgba(255, 255, 255, 0.9);
  color: #121307;
  font-size: 4rem;
  font-weight: bold;
  border: 0.5px solid #c2bdaf;
  font-size: 1.5rem;
  border-spacing: 0.5em;
  overflow: scroll;
}

.productList th {
  text-align: start;
}
.product-page {
  min-height: 100vh;
}
.productTables {
  display: flex;
  width: 100vw;
  gap: 1rem;
  justify-content: center;
  resize: both;
}

.productImage {
  overflow-y: hidden;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  position: fixed;
  z-index: -1;
  bottom: 0;
  width: 100%;
}

#shop {
  padding: 10px;
  margin-top: 15px;
  font-family: "Architects Daughter", cursive;
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  background-color: #54b3d6;
  border: 0.5px solid #a4e7ff;
  border-radius: 4px;
  box-shadow: 0 0 10px #54b3d6;
}
#shop a {
  margin: 0 -0.25rem;
  padding: 0 0.25rem;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 #54b3d6;
}
#shop:link {
  color: #2f3214;
}
#shop:visited {
  color: #2f3214;
}
#shop:hover {
  color: white;
  box-shadow: inset 100px 0 0 0 #54b3d6;

  font-weight: bolder;
}

@media only screen and (min-width: 900px) {
  #shop {
    font-size: 1.5rem;
  }
}
