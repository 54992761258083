.productsdiv {
  font-family: Comic Neue, cursive;
}
.productHeader {
  font-size: 35px;
  text-decoration: none;
  color: #304e52;
  width: 100%;
}
.productHeader:visited {
  color: #304e52;
}
.productHeader:hover {
  color: #304e52;
}

.productPage {
  width: 100%;
  height: 75%;
  border-radius: 4%;
}

.individualproduct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  margin-bottom: 20px;
  width: 70%;
}

.product {
  display: grid;

  grid: 80%;
  width: 80%;

  margin: 0 auto;
  align-items: center;
  justify-items: center;
}

@media only screen and (min-width: 700px) {
  .product {
    grid-template-columns: repeat(3, 1fr);
    margin: auto;
  }

  .individualproduct {
    margin: 10px;
  }
}
