#contactInfo {
  font-family: "Comic Neue", cursive;
  height: 100%;
}

#contactInfo > h1 {
  font-size: 5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  color: #42c6ca;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

#contactInfo .details {
  display: flex;
  flex-direction: column;
  margin: 3rem;
}

#contactInfo .details .line {
  content: "";
  border: 1.5px solid #2f3214;
  height: 450px;
  border-radius: 1.5px;
  display: none;
}

#contactInfo .details .hours {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contactInfo .details .contactMethods {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#contactInfo .details .hours table {
  border-spacing: 1rem;
}

#contactInfo .details .hours td {
  text-align: center;
}

#contactInfo .address,
.phone,
.email {
  color: #2f3214;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  padding-top: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
}

#contactInfo .icon {
  width: 18px;
  margin-right: 0.4rem;
}

#contactInfo .mapContainer {
  height: 600px;
  width: 95%;
  margin: 0 auto;
}

#map {
  width: 100%;
  height: 100%;
  border: 3px dashed #afe0ee;
  border-radius: 5px;
}

@media only screen and (min-width: 700px) {
  #contactInfo {
    font-family: "Comic Neue", cursive;
    font-size: larger;
    height: 100%;
  }

  #contactInfo > h1 {
    font-size: 5rem;
    margin-bottom: 0.5rem;
    margin-left: 1.5rem;
    text-align: left;
  }

  #contactInfo .details {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }

  #contactInfo .details .contactMethods {
    justify-content: baseline;
    text-align: left;
  }

  #contactInfo .details .contactMethods .method {
    width: 100%;
  }

  #contactInfo .details .line {
    display: block;
  }

  #contactInfo .details .inset {
    margin-left: 2rem;
  }

  #contactInfo .address,
  .phone,
  .email {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 1.2rem;
    padding-top: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
  }

  #contactInfo .address:visited,
  .phone:visited,
  .email:visited {
    color: #2f3214;
  }

  #contactInfo .address:hover,
  .phone:hover,
  .email:hover {
    color: #cfcf2b;
  }
}
