#header {
  font-family: "KGBeneathYourBeautifulChunk";
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex: row;
  align-items: center;
  justify-content: center;

  background-color: #f8ffff;
}
.headerText {
  font-size: 1.2rem;
}
#barsmenu {
  margin-top: 15px;
}
.nav {
  display: none;
}
.myNav {
  display: none;
}
/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 1px;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  left: 0;
  top: 0;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  background-color: #a0d1df34; /*Black fallback color */
  /* background-color: #A0D1DF; Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
  padding: 0.5rem;
  text-decoration: none;
  font-size: 2rem;
  color: #173008;
  text-shadow: 0 0 1px #fffefd;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover,
.overlay a:focus {
  color: #173008;
  text-shadow: 0 0 1px #fffefd;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  background-color: transparent;
  border: none;
  transition: ease-in-out 250ms;
}

.overlay .closebtn:hover {
  transform: scale(1.1);
}

.overlay .closebtn:active {
  transform: scale(0.9);
}

.headerText {
  padding-left: 5px;
  padding-right: 5px;
}

#nav {
  display: flex;
  padding-right: 5px;
}

.logo {
  width: 19rem;
  margin-bottom: 1rem;
}
/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media only screen and (min-width: 700px) {
  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2f3214;
    margin-right: 2rem;
    text-align: center;
  }
  .burgerMenu {
    display: none;
  }
  #header {
    top: 0;
    display: flex;
    flex: row;
    align-items: center;
    justify-content: space-between;
  }
  .headerText {
    font-size: 2rem;
    text-decoration: none;
  }
  .headerText:link {
    color: #2f3214;
  }
  .headerText:visited {
    color: #2f3214;
  }
  .headerText:hover {
    color: #8e92c9;
  }
  .logo {
    width: 280px;
  }

  .overlay a {
    visibility: hidden;
  }
  .overlay .closebtn {
    visibility: hidden;
  }
}
